import './ShareMapDetail.scss';
import './ShareMapList.scss';
import './ShareMapItem.scss';
import './Wrap.scss';
import './DyingHeader.scss';
import './ContentItem.scss';
import './ContentList.scss';
type HeadersProps = {
    selected : String;
  }
const DyingPresenter = ({selected} : HeadersProps) => {
    const DyingPresenterTop = () => {
        return (
            <div style={{ padding: '170px 10px 0' }}>
                <div className='ShareMapList'>
    
                    <div className='SchoolDownload'>
                        <div className='school-download-wrap'>
                            <div className='school-download-pc'>
                                <div className='school-download-pc-button'>
                                    {/* <button>WebGL 콘텐츠 샘플</button> */}
                                </div>
                            </div>
                        </div>
    
                        <div className='ContentList'>
    
                            <div className='content-list'>
    
    
                                
                                <div className='ContentItem'
                                    // onClick={(event) => {
                                    //     event.preventDefault();
                                    //     // window.open('https://kr1-api-object-storage.nhncloudservice.com/v1/AUTH_4da40ab7f9d14b998a98d89a4810f18b/Demo/WebGL/2/index.html');
                                    //     window.open('https://vrware-test.s3.ap-northeast-2.amazonaws.com/dyingPatientCare_WebGL/Sun/index.html');
                                    // }}
                                >
                                    <div className='content-thumb' style={{ backgroundImage: 'url(/images/dyingPatientCare/easy_1.jpg)', backgroundPosition: '50%', backgroundSize: 'cover' }} />
                                    <div className='share-map-info'>
                                        <p className='share-map-title1'>초급 - 일상 케어 훈련</p>

                                    </div>
                                </div>
                                <div className='ContentItem'
                                    // onClick={(event) => {
                                    //     event.preventDefault();
                                    //     window.open('https://vrware-test.s3.ap-northeast-2.amazonaws.com/dyingPatientCare_WebGL/Temperture/index.html');
                                    // }}
                                >
                                    <div className='content-thumb' style={{ backgroundImage: 'url(/images/dyingPatientCare/easy_2.jpg)', backgroundPosition: '50%', backgroundSize: 'cover' }} />
                                    <div className='share-map-info'>
                                        <p className='share-map-title1'>초급 - 홈 케어 훈련</p>
                                    </div>
                                </div>
                        
                            </div>
                        </div>
    
                    </div>
    
                </div>
            </div>
        );
    
    };



    const DyingPresenterMiddle = () => {
        return (
            <div style={{ padding: '170px 10px 0' }}>
                <div className='ShareMapList'>
    
                    <div className='SchoolDownload'>
                        <div className='school-download-wrap'>
                            <div className='school-download-pc'>
                                <div className='school-download-pc-button'>
                                    {/* <button>WebGL 콘텐츠 샘플</button> */}
                                </div>
                            </div>
                        </div>
    
                        <div className='ContentList'>
    
                            <div className='content-list'>
    
    
                                
                                <div className='ContentItem'
                                    // onClick={(event) => {
                                    //     event.preventDefault();
                                    //     // window.open('https://kr1-api-object-storage.nhncloudservice.com/v1/AUTH_4da40ab7f9d14b998a98d89a4810f18b/Demo/WebGL/2/index.html');
                                    //     window.open('https://vrware-test.s3.ap-northeast-2.amazonaws.com/dyingPatientCare_WebGL/Sun/index.html');
                                    // }}
                                >
                                    <div className='content-thumb' style={{ backgroundImage: 'url(/images/dyingPatientCare/normal_1.jpg)', backgroundPosition: '50%', backgroundSize: 'cover' }} />
                                    <div className='share-map-info'>
                                        <p className='share-map-title1'>중급 - 파킨슨 환자 케어 훈련</p>
                                    </div>
                                </div>
                                <div className='ContentItem'
                                    // onClick={(event) => {
                                    //     event.preventDefault();
                                    //     window.open('https://vrware-test.s3.ap-northeast-2.amazonaws.com/dyingPatientCare_WebGL/Temperture/index.html');
                                    // }}
                                >
                                    <div className='content-thumb' style={{ backgroundImage: 'url(/images/dyingPatientCare/normal_2.jpg)', backgroundPosition: '50%', backgroundSize: 'cover' }} />
                                    <div className='share-map-info'>
                                        <p className='share-map-title1'>중급 - 뇌졸증 환자 케어 훈련</p>
                                    </div>
                                </div>    
                            </div>
                        </div>
    
                    </div>
    
                </div>
            </div>
        );
    
    };




    const DyingPresenterBottom = () => {
        return (
            <div style={{ padding: '170px 10px 0' }}>
                <div className='ShareMapList'>
    
                    <div className='SchoolDownload'>
                        <div className='school-download-wrap'>
                            <div className='school-download-pc'>
                                <div className='school-download-pc-button'>
                                    {/* <button>WebGL 콘텐츠 샘플</button> */}
                                </div>
                            </div>
                        </div>
    
                        <div className='ContentList'>
    
                            <div className='content-list'>
    
    
                                
                                <div className='ContentItem'
                                    // onClick={(event) => {
                                    //     event.preventDefault();
                                    //     // window.open('https://kr1-api-object-storage.nhncloudservice.com/v1/AUTH_4da40ab7f9d14b998a98d89a4810f18b/Demo/WebGL/2/index.html');
                                    //     window.open('https://vrware-test.s3.ap-northeast-2.amazonaws.com/dyingPatientCare_WebGL/Sun/index.html');
                                    // }}
                                >
                                    <div className='content-thumb' style={{ backgroundImage: 'url(/images/dyingPatientCare/hard_1.jpg)', backgroundPosition: '50%', backgroundSize: 'cover' }} />
                                    <div className='share-map-info'>
                                        <p className='share-map-title1'>고급 - 치매 환자 케어 훈련</p>
                                    </div>
                                </div>
                                <div className='ContentItem'
                                    // onClick={(event) => {
                                    //     event.preventDefault();
                                    //     window.open('https://vrware-test.s3.ap-northeast-2.amazonaws.com/dyingPatientCare_WebGL/Temperture/index.html');
                                    // }}
                                >
                                    <div className='content-thumb' style={{ backgroundImage: 'url(/images/dyingPatientCare/hard_2.jpg)', backgroundPosition: '50%', backgroundSize: 'cover' }} />
                                    <div className='share-map-info'>
                                        <p className='share-map-title1'>고급 - 임종기 환자 케어 훈련</p>
                                    </div>
                                </div>
     
                            </div>
                        </div>
    
                    </div>
    
                </div>
            </div>
        );
    
    };



    const DyingPresenterAll = () => {
        return (
            <div style={{ padding: '170px 10px 0' }}>
                <div className='ShareMapList'>
    
                    <div className='SchoolDownload'>
                        <div className='school-download-wrap'>
                            <div className='school-download-pc'>
                                <div className='school-download-pc-button'>
                                    {/* <button>WebGL 콘텐츠 샘플</button> */}
                                </div>
                            </div>
                        </div>
    
                    <div className='ContentList'>
                        <div className='content-sub'>
                            <div className='content-list'>
                                <div className='ContentItem' 
                                    // onClick={(event) => {
                                    //     event.preventDefault();
                                    //     // window.open('https://kr1-api-object-storage.nhncloudservice.com/v1/AUTH_4da40ab7f9d14b998a98d89a4810f18b/Demo/WebGL/2/index.html');
                                    //     window.open('https://vrware-test.s3.ap-northeast-2.amazonaws.com/dyingPatientCare_WebGL/Sun/index.html');
                                    // }}
                                >
                                    <div className='content-thumb' style={{ backgroundImage: 'url(/images/dyingPatientCare/easy_1.jpg)', backgroundPosition: '50%', backgroundSize: 'cover' }} />
                                    <div className='share-map-info'>
                                        <p className='share-map-title1'>초급 - 일상 케어 훈련</p>
                                    </div>
                                </div>
                                <div className='ContentItem'
                                    // onClick={(event) => {
                                    //     event.preventDefault();
                                    //     window.open('https://vrware-test.s3.ap-northeast-2.amazonaws.com/dyingPatientCare_WebGL/Temperture/index.html');
                                    // }}
                                >
                                    <div className='content-thumb' style={{ backgroundImage: 'url(/images/dyingPatientCare/easy_2.jpg)', backgroundPosition: '50%', backgroundSize: 'cover' }} />
                                    <div className='share-map-info'>
                                        <p className='share-map-title1'>초급 - 홈 케어 훈련</p>
                                    </div>
                                </div>

                            </div>
                        </div>
     
                    <div className='content-sub'>
                        <div className='content-list'>
                            <div className='ContentItem' 
                                    // onClick={(event) => {
                                    //     event.preventDefault();
                                    //     // window.open('https://kr1-api-object-storage.nhncloudservice.com/v1/AUTH_4da40ab7f9d14b998a98d89a4810f18b/Demo/WebGL/2/index.html');
                                    //     window.open('https://vrware-test.s3.ap-northeast-2.amazonaws.com/dyingPatientCare_WebGL/Sun/index.html');
                                    // }}
                                >
                                    <div className='content-thumb' style={{ backgroundImage: 'url(/images/dyingPatientCare/normal_1.jpg)', backgroundPosition: '50%', backgroundSize: 'cover' }} />
                                    <div className='share-map-info'>
                                        <p className='share-map-title1'>중급 - 파킨슨 환자 케어 훈련</p>
                                    </div>
                                </div>
                                <div className='ContentItem'
                                    // onClick={(event) => {
                                    //     event.preventDefault();
                                    //     window.open('https://vrware-test.s3.ap-northeast-2.amazonaws.com/dyingPatientCare_WebGL/Temperture/index.html');
                                    // }}
                                >
                                    <div className='content-thumb' style={{ backgroundImage: 'url(/images/dyingPatientCare/normal_2.jpg)', backgroundPosition: '50%', backgroundSize: 'cover' }} />
                                    <div className='share-map-info'>
                                        <p className='share-map-title1'>중급 - 뇌졸중 환자 케어 훈련</p>
                                    </div>
                                </div>
                            </div>
                    </div>

                <div className='content-sub'>
                    <div className='content-list'>
                            <div className='ContentItem' 
                                    // onClick={(event) => {
                                    //     event.preventDefault();
                                    //     // window.open('https://kr1-api-object-storage.nhncloudservice.com/v1/AUTH_4da40ab7f9d14b998a98d89a4810f18b/Demo/WebGL/2/index.html');
                                    //     window.open('https://vrware-test.s3.ap-northeast-2.amazonaws.com/dyingPatientCare_WebGL/Sun/index.html');
                                    // }}
                                >
                                    <div className='content-thumb' style={{ backgroundImage: 'url(/images/dyingPatientCare/hard_1.jpg)', backgroundPosition: '50%', backgroundSize: 'cover' }} />
                                    <div className='share-map-info'>
                                        <p className='share-map-title1'>고급 - 치매 환자 케어 훈련</p>
                                    </div>
                                </div>
                                <div className='ContentItem'
                                    // onClick={(event) => {
                                    //     event.preventDefault();
                                    //     window.open('https://vrware-test.s3.ap-northeast-2.amazonaws.com/dyingPatientCare_WebGL/Temperture/index.html');
                                    // }}
                                >
                                    <div className='content-thumb' style={{ backgroundImage: 'url(/images/dyingPatientCare/hard_2.jpg)', backgroundPosition: '50%', backgroundSize: 'cover' }} />
                                    <div className='share-map-info'>
                                        <p className='share-map-title1'>고급 - 임종기 환자 케어 훈련</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                            </div>
                        </div>
    
                    </div>
    
                </div>
        );
    
    };




    return(
        <div >
            {
              selected === 'top'
              ? <DyingPresenterTop/>
              : (selected === 'mid'? <DyingPresenterMiddle/> 
              : selected === 'bot' ?  <DyingPresenterBottom/> 
              : <DyingPresenterAll/> )
      
            }
        </div>
      );
       



};




export default DyingPresenter;