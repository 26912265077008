import { NotFoundPage } from 'pages/error/NotFoundPage';
import { MainPage } from 'pages/MainPage';
import { NursingSkillPage } from 'pages/NursingSkillPage';
import { DyingPatientCarePage } from 'pages/DyingPatientCarePage'
import { BrowserRouter, Route, Routes, Link, Navigate } from "react-router-dom";

const App = () => {
  return (
    <Routes>
        <Route path='/' element={<MainPage/>} />
        <Route path='/nursing-skill' element={<NursingSkillPage/>} />
        <Route path='/dyingPatient-care' element={<DyingPatientCarePage/>} />
        <Route element={<NotFoundPage/>} />
    </Routes>
  );
};

export default App;
