import './Footer.scss';

export const Footer = () => {
  return (
    <div id='test' className='footer-wrap'>
      <div className='footer-box'>

        <div className='footer-center'>
          <div className='footer-center-text'>
            {/* <div className='footer-center-top'>
              <Link to='/policy/privacy'>
                <p>개인정보처리방침이용약관</p>
              </Link>
              <p>|</p>
              <Link to='/policy/service'>
                <p>이용약관</p>
              </Link>
            </div> */}
            <div className='footer-center-bottom'>
              <div className='text-group-1'>
                <p>(주)글로브포인트</p>
              </div>
              <div className='text-group-2'>
                <p>경기도 고양시 덕양구 삼원로 83, 광양프런티어밸리 6차 1111호  (10550)</p>
              </div>
              <div className='group'>
                <div className='img-group'>
                <img src={'/images/footer/footer1.png'} alt='phone' />
                  <span> 031-911-0601</span>
                </div>
                <div className='img-group'>
                <img src={'/images/footer/footer2.png'} alt='phone' />
                  <span> 031-922-0602</span>
                </div>
                <div className='img-group'>
                <img src={'/images/footer/footer3.png'} alt='phone' />
                  <span> gpsales@globepoint.co.kr</span>
                </div>
              </div>
              <p className='copyrights'>Copyrights ⓒ 2019 Globepoint Inc. All rights reserved.</p>
            </div>
          </div>
        </div>

      </div>
      <FooterBottomSNS />
    </div>
  );
};

const FooterSNS = () => {
  return (
    <ul>
      <li>
        <a href='https://www.youtube.com/channel/UCZGwK7Ac87irDmPZfoLPvlg' target='_sub'>
          <img src={'/images/footer/youtube.png'} alt='youtube' />
        </a>
      </li>
      <li>
        <a href='https://www.facebook.com/GlobePoint.Inc/' target='_sub'>
          <img src={'/images/footer/facebook.png'} alt='facebook' />
        </a>
      </li>
      <li>
        <a href='https://twitter.com/GlobepointK' target='_sub'>
          <img src={'/images/footer/twitter.png'} alt='twitter' />
        </a>
      </li>
      <li>
        <a href='http://blog.vrware.co.kr/' target='_sub'>
          <img src={'/images/footer/blog.png'} alt='blog' />
        </a>
      </li>
    </ul>
  );
};

const FooterBottomSNS = () => {
  return (
    <div className='footer-bottom-sns'>
      <ul>
        <li>
          <a href='https://www.youtube.com/channel/UCZGwK7Ac87irDmPZfoLPvlg' target='_sub'>
            <img src={'/images/footer/youtube.png'} alt='youtube' />
          </a>
        </li>
        <li>
          <a href='https://www.facebook.com/GlobePoint.Inc/' target='_sub'>
            <img src={'/images/footer/facebook.png'} alt='facebook' />
          </a>
        </li>
        <li>
          <a href='https://twitter.com/GlobepointK' target='_sub'>
            <img src={'/images/footer/twitter.png'} alt='twitter' />
          </a>
        </li>
        <li>
          <a href='http://blog.vrware.co.kr/' target='_sub'>
            <img src={'/images/footer/blog.png'} alt='blog' />
          </a>
        </li>
      </ul>
    </div>
  );
};
