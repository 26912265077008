import './NursingSkillBanner.scss';
import { isMobile } from 'lib/isMobile';
import { useCallback } from 'react';

export const NursingSkillBanner = () => {

  const onClickWebGL = useCallback(() => {
    
    if (isMobile()) {
      alert('PC버전에서 이용하시기 바랍니다.');
      return;
    }


    window.location.href = 'https://nursingskill.mrware.us/web/index.html';
    
  }, []);


  const onClickInstaller = useCallback(() => {
    
    if (isMobile()) {
      alert('PC버전에서 이용하시기 바랍니다.');
      return;
    }


    window.location.href = 'https://kr1-api-object-storage.nhncloudservice.com/v1/AUTH_81b6b8c355154c84b73c941f22933b21/PC/MRWARE%20NursingSkill.exe';
    
  }, []);

  const onClickManual = useCallback(() => {
    
    window.open('https://kr1-api-object-storage.nhncloudservice.com/v1/AUTH_4da40ab7f9d14b998a98d89a4810f18b/images/%ED%95%B5%EC%8B%AC%EA%B0%84%ED%98%B8%EC%88%A0%EA%B8%B0/%ED%95%B5%EC%8B%AC%EA%B0%84%ED%98%B8%EC%88%A0%EA%B8%B0%20%EC%BD%98%ED%85%90%EC%B8%A0%20%ED%99%9C%EC%9A%A9%20%EB%A7%A4%EB%89%B4%EC%96%BC_V.02%20(1).pdf');

    //window.location.href = 'https://kr1-api-object-storage.nhncloudservice.com/v1/AUTH_4da40ab7f9d14b998a98d89a4810f18b/images/%ED%95%B5%EC%8B%AC%EA%B0%84%ED%98%B8%EC%88%A0%EA%B8%B0/%ED%95%B5%EC%8B%AC%EA%B0%84%ED%98%B8%EC%88%A0%EA%B8%B0%20%EC%BD%98%ED%85%90%EC%B8%A0%20%ED%99%9C%EC%9A%A9%20%EB%A7%A4%EB%89%B4%EC%96%BC_V.02%20(1).pdf';
    
  }, []);


  return (
    <div className='NursingSkillBanner'>
      <div style={{ backgroundImage: `url('/images/nursing-skill/1.jpg')` }} className='nursing-skill-banner-bg'>
        <div className='nursing-skill-banner-txt-wrap'>
          <div className='nursing-skill-banner-title'>핵심간호술기</div>
          <div className='nursing-skill-banner-desc'>학교 및 집에서도 실습 및 평가 가능한 시스템</div>
          <div className='nursing-skill-banner-btns'>
            
              <div style={{cursor: 'pointer'}} className='nursing-skill-banner-btn btn-left' onClick={() => onClickWebGL()}>
                <button style={{cursor: 'pointer'}}>웹에서 실행하기
                  </button>
              </div>

              <div style={{cursor: 'pointer'}} className='nursing-skill-banner-btn btn-right' onClick={() => onClickInstaller()}>
                <button style={{cursor: 'pointer'}}>설치버전 다운받기
                <img src={'/images/nursing-skill/install_arrow.png'} alt='9' />
                  </button>
              </div>
            
            
            {/* 
            <a href='https://nursing.mrware.us' target='_blank' rel='noreferrer'>
              <div className='nursing-skill-banner-btn btn-right'>실습결과 바로가기</div>
            </a>
            */}

          </div>
          <div className='nursing-skill-banner-bottom-btns'>
          <div style={{cursor: 'pointer'}} className='nursing-skill-banner-bottom-btn btn' onClick={() => onClickManual()}>
                <button style={{cursor: 'pointer'}}>핵심간호술기 매뉴얼 다운로드
                <img src={'/images/nursing-skill/Exclude.png'} alt='9' />
                  </button>
              </div>
          </div>
        </div>
      </div>
    </div>
  );
};
