import React, {useState, useEffect} from "react";
import './TopButton.scss'

export const TopButton = () => {

    const [showButton, setShowButton] = useState(false);

  const scrollToTop = () => {
    window.scroll({
      top: 0,
      behavior: 'auto',
    });
  };
  useEffect(() => {
    const handleShowButton = () => {
      window.scrollY > 100 ? setShowButton(true) : setShowButton(false);
    };

    console.log(window.scrollY);
    window.addEventListener('scroll', handleShowButton);
    return () => {
      window.removeEventListener('scroll', handleShowButton);
    };
  }, []);
  return (
    <>
    {showButton && (
      <div className="scroll">
        <button onClick={scrollToTop} className='back-to-top'>
        <img src={'/images/nursing-skill/scrolltop.png'} alt='scroll' />
        </button>
      </div>
    )}
     </>
  );
  }