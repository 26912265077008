import { Footer } from 'components/base/Footer/Footer';
import { ContentsTemplate } from 'components/base/ContentsTemplate/ContentsTemplate';
import { NursingSkillBanner } from 'components/nursingSkill/NursingSkillBanner/NursingSkillBanner';
import { NursingSkillIntro } from 'components/nursingSkill/NursingSkillIntro/NursingSkillIntro';
import { TopButton } from 'components/nursingSkill/NursingSkillButton/TopButton';
export const NursingSkillPage = () => {
  return (
    <ContentsTemplate footer={<Footer />}>
      <NursingSkillBanner />
      <NursingSkillIntro />
      <TopButton/>
    </ContentsTemplate>
  );
};
