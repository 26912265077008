import { useEffect } from 'react';

type TemplateProps = {
  header?: JSX.Element;
  children: React.ReactNode;
  footer?: JSX.Element;
};

export const ContentsTemplate = ({ header, children, footer }: TemplateProps) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      {header}
        <main className='main' style={{ position: 'relative', height: '100%' }}>{children}</main>
      {footer}
    </div>
  );
};
