import './NursingSkillIntro.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';

export const NursingSkillIntro = () => {
  const settings = {
    dots: true,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (




    <div className='NursingSkillIntro'>



      <div className='nursing-skill-intro-wrap'>

        <div className='first'>
          <div className='contents-1'>
            <div className='first-icon'>
              <img src={'/images/nursing-skill/first/first-icon-1.png'} alt='9' />
            </div>
            <div className='first-title'>
              <p>Student</p>
            </div>
            <div className='first-text'>
              <p>언제 어디서나 실습을</p>
              <p>할 수 있어서 좋아요.</p>
              <p>필요한 주제를 반복해서</p>
              <p>할 수 있어 좋아요.</p>
            </div>

          </div>
          <div className='contents-2'>
          <div className='first-icon'>
              <img src={'/images/nursing-skill/first/first-icon-2.png'} alt='9' />
            </div>
            <div className='first-title'>
              <p>Professor</p>
            </div>
            <div className='first-text'>
              <p>학생들별 학습 및 습득</p>
              <p>현황 파악과 피드백이</p>
              <p>가능해서 좋네요.</p>
              <p>핵심간호술기의 점수화</p>
              <p>및 관리가 가능해요.</p>
            </div>
          </div>
          <div className='contents-3'>
            <div className='first-icon'>
              <img src={'/images/nursing-skill/first/first-icon-3.png'} alt='9' />
            </div>
            <div className='first-title'>
              <p>School</p>
            </div>
            <div className='first-text'>
            <p>부족했던 전용 실습실</p>
              <p>문제가 해결되었어요.</p>
              <p>학생들에게 새로운 실습</p>
              <p>환경을 제공할 수 있어요.</p>
            </div>
          </div>
        </div>




        <div className='second'>
          <h1 className='nursing-skill-name'>
            가상현실(VR)을 통한 핵심간호술기 교육 콘텐츠
            {/* <div className='nursing-skill-name-line' /> */}
          </h1>
          <div className='nursing-skill-desc'>
            <p>Nursing Skills(핵심간호술기)는 360VR 기반의 실감형 실습교육 플랫폼으로서,</p>
            <p>18개 주제에 대한 콘텐츠와 실습 현황 및</p>
            <p>결과를 확인할 수 있는 학습 관리로 구성되어 있습니다.</p>
          </div>

          <div className='second-content'>
            <div className='second-left'>
              <div className='video'>
                <div className='video-background'>
                  <div className='video-desc'>
                    <video className='player'
                      autoPlay
                      muted
                      loop
                    >
                      <source
                        src="https://kr1-api-object-storage.nhncloudservice.com/v1/AUTH_eecaade34a5942ebbcd67c8630ddddff/front-page/nursing_video.mp4"
                        type='video/mp4' />
                    </video>
                  </div>
                </div>
                <img src={'/images/nursing-skill/second/mac.png'} />
              </div>
            </div>

            <div className='second-right'>

              <div className='box1'>
                <div className='box-icon'>
                  <img src={'/images/nursing-skill/second/icon-1.png'} />
                </div>
                <div className='text-group'>
                  <div className='text-title'><p>현장감</p></div>
                  <div className='text-desc'><p>간호데스크 및 병실에 대한 실사 촬영으로 현장감 증대</p></div>
                </div>
              </div>

              <div className='box2'>
                <div className='box-icon'>
                  <img src={'/images/nursing-skill/second/icon-2.png'} />
                </div>
                <div className='text-group'>
                  <div className='text-title'><p>실습</p></div>
                  <div className='text-desc'><p>장소와 시간에 구애 받지 않고 각 주제에 대한 절차 학습</p>
                    <p>실습절차에 대한 반복학습</p>
                  </div>
                </div>

              </div>

              <div className='box3'>
                <div className='box-icon'>
                  <img src={'/images/nursing-skill/second/icon-3.png'} />
                </div>
                <div className='text-group'>
                  <div className='text-title'><p>평가</p></div>
                  <div className='text-desc'><p>학생의 실습 결과에 대한 평가 데이터를 교수자 확인이 가능한 시스템 구축</p></div>
                </div>
              </div>
            </div>

          </div>

        </div>



        <div className='third'>
          <div className='top-text-group'>
            <div className='third-title'>
              <p>실감형 실습</p>
            </div>
            <div className='third-desc'>
              <p>18개의 주제별 절차학습과 평가가 가능한 콘텐츠</p>
            </div>
          </div>
          <div className='nursing-skill-point-list'>

            <div className='nursing-skill-point-item'>
              <div className='nursing-skill-point-ex-imgs'>
                <div>

                  <div className='nursing-skill-point-ex-img'>
                    <img src={'/images/nursing-skill/third/mm1.png'} alt='test' />
                  </div>
                  <div>
                    <p>실습 바로가기</p>
                  </div>
                  <div className='nursing-skill-point-ex-img'>
                  <img src={'/images/nursing-skill/third/mm2.png'} alt='test' />
                  </div>
                  <div>
                    <p>목표 및 상황 제시</p>
                  </div>
                </div>
              </div>
            </div>

            <div className='nursing-skill-point-item'>
              <div className='nursing-skill-point-ex-imgs'>
                <div>

                  <div className='nursing-skill-point-ex-img'>
                  <img src={'/images/nursing-skill/third/mm3.png'} alt='test' />
                  </div>
                  <div>
                    <p>학습모드와 평가모드</p>
                  </div>
                  <div className='nursing-skill-point-ex-img'>
                  <img src={'/images/nursing-skill/third/mm4.png'} alt='test' />
                  </div>
                  <div>
                    <p>선택형 문항</p>
                  </div>
                </div>
              </div>
            </div>

            <div className='nursing-skill-point-item'>
              <div className='nursing-skill-point-ex-imgs'>
                <div>
                  <div className='nursing-skill-point-ex-img'>
                  <img src={'/images/nursing-skill/third/mm5.png'} alt='test' />
                  </div>
                  <div>
                    <p>핵심기본간호술 항목선택</p>
                  </div>
                  <div className='nursing-skill-point-ex-img'>
                  <img src={'/images/nursing-skill/third/mm6.png'} alt='test' />
                  </div>
                  <div>
                    <p>보충영상</p>
                  </div>
                </div>
              </div>
            </div>

            <div className='nursing-skill-point-item'>
              <div className='nursing-skill-point-ex-imgs'>
                <div>
                  <div className='nursing-skill-point-ex-img'>
                  <img src={'/images/nursing-skill/third/mm7.png'} alt='test' />
                  </div>
                  <div>
                    <p>목표 및 상황 제시</p>
                  </div>
                  <div className='nursing-skill-point-ex-img'>
                  <img src={'/images/nursing-skill/third/mm8.png'} alt='test' />
                  </div>
                  <div>
                    <p>음성인식</p>
                  </div>
                </div>
              </div>
            </div>

          </div>


          <div className='third-bottom-group'>
            <p>언제, 어디서나(학교 및 집, 카페에서도) 실습 및 평가 가능 / 360VR(동영상) 기반의 상호작용이 가능한 실습 시스템</p>
            <p>단계(오브젝트) 선택 및 음성인식 기능 활용 / 단계별 학습 정보 제공(이미지, 동영상 등)</p>
          </div>

        </div>




        <div className='four'>
          <div className='top-text-group'>
            <div className='third-title'>
              <p>실습 및 평가 관리</p>
            </div>

          </div>
          <div className='nursing-skill-point-list'>

            <div className='nursing-skill-point-item'>
              <div className='nursing-skill-point-ex-imgs'>
                <div>

                  <div className='left-group'>

                    <div className='title'>
                      <p className='title-text' style={{ width: '70%' }}>실습 평가</p>
                      <div className='desc'>
                        <p>학번을 활용한 로그인</p>
                        <p>주제별 올바른 절차 수행 평가</p>
                        <p>주제별 수행 시간 평가</p>
                      </div>
                    </div>

                  </div>

                  <div className='left-group' style={{ marginTop: '6rem' }}>

                    <div className='title'>
                      <p className='title-text'>교수자 페이지</p>
                      <div className='desc'>
                        <p>실습 평가 결과 전용 페이지를 통한 실습수행 결과 확인</p>
                        <p>실습 내용에 대한 피드백</p>
                        <p>전용 서버 구축</p>
                      </div>
                    </div>

                  </div>

                </div>
              </div>
            </div>

            <div className='nursing-skill-point-item' style={{ width: '25%' }}>
              <div className='nursing-skill-point-ex-imgs'>
                <div>

                  <div className='nursing-skill-point-ex-img'>
                    <img src={'/images/nursing-skill/four/test1.png'} alt='test' />
                  </div>
                  <div>
                    <p>실습결과 바로가기</p>
                  </div>
                  <div className='nursing-skill-point-ex-img' style={{marginTop: '5rem'}}>
                  <img src={'/images/nursing-skill/four/test2.png'} alt='test' />
                  </div>
                  <div>
                    <p>교수자: 학생 실습 현황 관리</p>
                  </div>
                </div>
              </div>
            </div>

            <div className='nursing-skill-point-item' style={{ width: '25%' }}>
              <div className='nursing-skill-point-ex-imgs'>
                <div>
                  <div className='nursing-skill-point-ex-img'>
                  <img src={'/images/nursing-skill/four/test3.png'} alt='test' />
                  </div>
                  <div>
                    <p>학생: 실습 현황 확인</p>
                  </div>
                  <div className='nursing-skill-point-ex-img' style={{marginTop: '5rem'}}>
                  <img src={'/images/nursing-skill/four/test4.png'} alt='test' />
                  </div>
                  <div>
                    <p>피드백 전달 및 확인</p>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>




        <div className='five'>
          <div className='nursing-skill-main-point1'>
            <h1 className='nursing-skill-main-point-title'>두 가지 접속환경 제공</h1>
            <div className='nursing-skill-main-point1-content'>

              <div className='top-content'>
                <div className='content-info'>
                  <div>
                    <img src={'/images/nursing-skill/five/five_1.png'} alt='test' />
                  </div>
                  <div className='btn'>
                    웹버전
                  </div>
                  <div className='desc'>
                    <p>웹페이지에서 바로 실행</p>
                    <p>장소 제약 없이 실습 가능</p>
                  </div>
                </div>

                <div className='content-info'>
                  <div>
                    <img src={'/images/nursing-skill/five/five_2.png'} alt='test' />
                  </div>
                  <div className='btn'>
                    설치버전
                  </div>
                  <div className='desc'>
                    <p>설치 파일 다운로드 후 실행</p>
                    <p>안정적인 구동 환경 제공</p>
                  </div>
                </div>

              </div>
              <div>

                <div>

                </div>

                <div className='table-group'>
                  <div>
                    <h1 style={{padding: '3rem'}}>권장사양 안내</h1>
                  </div>
                  <table className='download-spec-table'>
                    <thead>
                      <tr className='download-spec-head'>
                        <th className='download-col-first'>구분</th>
                        <th className='download-col-rest'>웹버전 권장사양</th>
                        <th className='download-col-rest'>설치버전 권장사양</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className='download-spec-info'>
                        <td className='download-col-first'>프로세서(CPU)</td>
                        <td colSpan={2} className='download-col-rest'>Intel® Core™ i5</td>
                      
                      </tr>
                      <tr className='download-spec-info'>
                        <td className='download-col-first'>메모리(RAM)</td>
                        <td colSpan={2} className='download-col-rest'>4GB RAM 또는 그 이상 </td>
                      </tr>
                      <tr className='download-spec-info'>
                        <td className='download-col-first'>운영체제(OS)</td>
                        <td className='download-col-rest'>
                          <p>Windows® 10 64-bit</p>
                          <p>(WebGL 2.0 이상 지원 브라우저)</p>
                          </td>
          
                        <td className='download-col-rest'>Windows® 10 64-bit </td>
                      </tr>
                      <tr className='download-spec-info'>
                        <td className='download-col-first'>하드디스크(HDD)</td>
                        <td className='download-col-rest'>-</td>
                        <td className='download-col-rest'>
                          <p>15GB 이상의</p>
                          <p>하드 드라이브 여유 공간</p>
                          </td>
                      </tr>
                    </tbody>


                  </table>
                </div>

              </div>
              
            </div>
          </div>
        </div>




      <div className='six'>
        <div className='nursing-skill-main-point2'>
          <h1 className='nursing-skill-main-point-title'>호환 HMD</h1>
          <div className='nursing-skill-main-point2-content'>
            <p className='nursing-skill-main-point-desc'>HMD를 통한 몰입감 있는 실습</p>
            <div className='nursing-skill-main-point2-list'>
              <div className='nursing-skill-main-point2-item'>
                <div className='nursing-skill-main-point2-item-img'>
                  <img src={'/images/nursing-skill/six/six_1.png'} alt='6' />
                </div>
                <div className='nursing-skill-main-point2-summary'>
                  <div className='point2-summary-title'>Pico G2 4K</div>
                  
                </div>
              </div>
              <div className='nursing-skill-main-point2-item'>
                <div className='nursing-skill-main-point2-item-img'>
                  <img src={'/images/nursing-skill/six/six_2.png'} alt='7' />
                </div>
                <div className='nursing-skill-main-point2-summary'>
                  <div className='point2-summary-title'>Meta Quest 2</div>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>


        

        
      </div>
    </div>
  );
};
