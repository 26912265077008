import React, { useState } from "react";
import styled from "styled-components";
import './selectBox.scss'
const DropDownContainer = styled("div")`
    position: absolute;
    top: 1em;
    right: 4em;
    background-color:white;
    height: 30px;
    width: 100px;
    &:hover{
      cursor:pointer;
    }
`;

const DropDownHeader = styled("div")`
    text-align: center;
    font-size: 1.3rem;
`;

const DropDownListContainer = styled("div")``;

const DropDownList = styled("ul")`
    height: 140px;
    width: 120px;
    top: 30px;
    background-color: rgb(255,255,255);
    line-height: 20px;
    border-radius: 10px;
    color: rgb(115,115,115);
    font-size: 15px;
    font-weight: 500;
    position: relative;
    left: -10px;
    z-index: 100;
    text-align: center;

::before {
  border-color: rgb(255, 255, 255) transparent;
  border-style: solid;
  border-width: 0px 6px 8px 6.5px;
  content: "";
  display: block;
  left: 50px;
  position: absolute;
  top: -7px;
  width: 0px;
  z-index: 0;
}
`;


const ListItem = styled("li")`
  list-style: none;
  margin-bottom: 0.8em;
  padding: 3px 0;
  :hover{
    color: #FCC500
  }
`;


type SelectBoxProps = {
  getOption : any;
  props :{ option: any[]; }
}


const SelectBox = ( {getOption, props} : SelectBoxProps) => {


  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const toggling = () => setIsOpen(!isOpen);



  const onOptionClicked = (value : any) => () => {
    setSelectedOption(value.value);
    setText(value.name);
    setIsOpen(false);
    getOption(value.value)
  };


  const [text, setText] = useState('모두');

  return (
      <div className="DropDownContainer">
        <div className="DropDownHeader" onClick={toggling}>
        <label>{text}</label>
        ▼
        {isOpen && (
          <div className="DropDownListContainer">
            <div className="DropDownList">
              {props.option.map(o => (
                <div className="ListItem" onClick={onOptionClicked(o)} key={o.value}>
                  {o.name}
                </div>
              ))}
            </div>
          </div>
        )}

        </div>
      </div>
 
  );
}

export default SelectBox;