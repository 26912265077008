import React, { useEffect } from 'react';
import { Footer } from 'components/base/Footer/Footer';
import { ContentsTemplate } from 'components/base/ContentsTemplate/ContentsTemplate2';
import { DyingHeader, DyingPresenter } from 'components/dyingPatient-Care';
import { selectAtom } from '../lib/atom';
import { useAtom } from 'jotai';
import { useLocation } from 'react-router-dom';


export const DyingPatientCarePage = () => {


  const [option, setOption] = useAtom(selectAtom);
  const onChangeOption = (e: { target: { value : string } }) => {
    setOption(e.target.value);
    console.log(e.target.value);
  };
  
  const location = useLocation();
  
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    let hl = params.get("hl");
    console.log("끼융" +hl);
    if(hl === 'ko'){
      setOption('ko');
    }else if(hl === 'en'){
      setOption('en');
    }else if(hl === 'ar'){
      setOption('ar');
    }
  }, [ location ])
  
  

  return (
    <ContentsTemplate footer={<Footer />}>
       <DyingHeader option={option} changeOption={onChangeOption}/>
        <DyingPresenter selected={option}/>
    </ContentsTemplate>
  );
};
