import { Link } from 'react-router-dom';
import './MainBanner.scss';

export const MainBanner = () => {
  return (
    <div className='MainBanner'>
      <div style={{ backgroundImage: `url('/images/main/main_1920.jpg')` }} className='main-banner-bg'>
        <div className='main-banner-header-wrap'>
          <div className='main-banner-title'>
            <label>M</label>ixed <label>R</label>eality
          </div>
          <div className='main-banner-desc'>
            <p>가상 세계와 현실 세계를 합쳐서</p>
            <p>새로운 환경이나 시각화 등 새로운 정보를 만들어 내는 것</p>
          </div>
        </div>

        <div className='main-banner-nav'>
          <div className='main-banner-nav-desc'>
            <p>체험 교육의 확장 및 개선을 위해</p>
            <p>
              <label>M</label>edical <label>R</label>eality <label>M</label>ixed
              <label>R</label>eality 시스템
            </p>
            <p>및 콘텐츠를 서비스 하겠습니다.</p>
          </div>
          <div className='main-banner-nav-list'>
            <Link to={'/nursing-skill'}>
              <div className='main-banner-nav-item nursing-nav-item'>
                <div>
                  <img src='/images/main/nursingskill.png' alt='nursing skill' />
                  <p>핵심간호술기</p>
                </div>
                <div>
                  <img src='/images/main/arrow.png' alt='arrow' />
                </div>
              </div>
            </Link>

            <div style={{cursor: 'pointer'}} onClick={() => {window.open('https://globepoint-common.s3.ap-northeast-2.amazonaws.com/ICareNext4G/PhysioterapyTest2/index.html')}} className='main-banner-nav-item health-nav-item'>
              <div>
                <img src='/images/main/health.png' alt='health' />
                <p>헬스케어</p>
              </div>
              <div>
                <img src='/images/main/arrow.png' alt='arrow' />
              </div>
            </div>

            <div className='main-banner-nav-item mr-nav-item'>
              <div>
                <img src='/images/main/mr.png' alt='MR Contents' />
                <p>MR 콘텐츠</p>
              </div>
              <div>
                <img src='/images/main/arrow.png' alt='arrow' />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
