import './DyingHeader.scss';
import { selectAtom } from '../../lib/atom';
import { useAtom } from 'jotai';
import { SelectBox } from './';



type HeaderProps = {
  option : string;
  changeOption: any;
}



const DyingHeader = ({option, changeOption} : HeaderProps) => {
  const [options, setOption] = useAtom(selectAtom);
  
  const getOption = (options : string) => {
    setOption(options);
  };
    return (
<div className='dying-header-wrapp'>
    <div className='header-box'>
        <div className='logo'>
          <a href='/Dying-demo'>
            <img className='vrware-logo' alt='로고' src={'/images/main/logo.png'}/>
          </a>
        </div>
        <div className='nav' id='nav'>
          <ul>
            <li id='nav-li'>
              <div className='nav-link'>
               <button className='nav-button'>요양보호사 CMS</button>
              </div>
            <div>
            {/* <a href="sglobepoint://hglobepoint?url=https://3dstudycube.com&data=0">Test Button</a> */}
            </div>
            </li>
          </ul>
        </div>
      </div>

      <div className='dropDown'>

        <SelectBox getOption={getOption} props={{
          option: [
            { value: "all", name: "모두" },
            { value: "top", name: "초급" },
            { value: "mid", name: "중급" },
            { value: "bot", name: "고급" },
          ]}}/>
      </div>


    </div>
);
};

export default DyingHeader;