import { Footer } from 'components/base/Footer/Footer';
import { Template } from 'components/base/Template/Template';
import { MainBanner } from 'components/main/MainBanner/MainBanner';

export const MainPage = () => {
  return (
    <Template footer={<Footer />}>
      <MainBanner />
    </Template>
  );
};
